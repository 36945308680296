<template>
  <div class="crawled-documents__table">
    <mds-modal
      v-model="isModalVisible"
      width="1200px"
      fullscreen
    >
      <mds-section
        border="none"
        :title="collectionSelected.name"
        bold
      >
        <template #mds-section-actions>
          <mds-button-container right-aligned>
            <mds-button
              type="button"
              variation="icon-only"
              icon="remove"
              @click="isModalVisible=!isModalVisible"
            >
              Close Modal
            </mds-button>
          </mds-button-container>
        </template>
        <crawled-documents
          v-if="isCollectionSelected"
          :collection-selected="collectionSelected"
          @updateModalIsVisible="updateModalIsVisible"
        />
      </mds-section>
    </mds-modal>
    <mds-row>
      <mds-col class="crawled-documents__title">
        {{ this.$t(`history.title`) }}
      </mds-col>
    </mds-row>
    <mds-row>
      <mds-col>
        <store-provider
          v-bind="$options.storeInfo"
        >
          <template>
            <mds-table row-hover>
              <mds-thead>
                <mds-th
                  v-for="header in $options.headers"
                  :key="header.fieldName"
                  :style="header.style"
                >
                  {{ header.text }}
                </mds-th>
              </mds-thead>
              <mds-tbody>
                <template
                  v-for="(row, index) in paginatedData"
                >
                  <mds-tr :key="index">
                    <mds-td
                      v-for="(header, i) in $options.headers"
                      :key="i"
                      :right-aligned="header.align === 'right'"
                      :style="header.style"
                    >
                      <template v-if="header.fieldName === 'stage'">
                        <state-tag
                          :status="row[header.fieldName]"
                          :color="getTagColor(row[header.fieldName])"
                        />
                      </template>
                      <template v-else-if="header.fieldName === 'status'">
                        <state-tag
                          :status="row[header.fieldName]"
                          :color="getTagColor(row[header.fieldName])"
                        />
                      </template>
                      <template v-else-if="header.fieldName === 'url'">
                        <mds-button
                          variation="icon-only"
                          :icon="row[header.fieldName] && row[header.fieldName] !== '' ? 'eye' : 'eye-crossed'"
                          type="button"
                          text="json"
                          :disabled="!row[header.fieldName] || row[header.fieldName] === ''"
                          @click="openReport(row.batchId)"
                        />
                      </template>
                      <template v-else-if="header.fieldName === 'lastExecutedDate' || header.fieldName === 'createdAt' || header.fieldName === 'crawlingTimeframeStart'">
                        {{ row[header.fieldName] | formatDate }}
                      </template>
                      <template v-else-if="header.fieldName === 'actions'">
                        <mds-button
                          variation="icon-only"
                          icon="eye"
                          text="View"
                          @click="toggleModal($event, row)"
                        />
                      </template>
                      <template v-else>
                        {{ row[header.fieldName] }}
                      </template>
                    </mds-td>
                  </mds-tr>
                </template>
              </mds-tbody>
            </mds-table>
          </template>
        </store-provider>
        <mds-pagination
          v-if="showPagination"
          :total-items="totalElements"
          :page="paginationOptions.page"
          :page-size="paginationOptions.pageSize"
          show-items-info
          show-items-select
          @mds-pagination-page-changed="handlePageChange"
        />
      </mds-col>
    </mds-row>
  </div>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider';
import { TABLE_HEADERS } from '@/constants/history-em.constant';
import { STAGES, STATUS } from '@/constants/status-tags-colors.constant';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import MdsPagination from '@mds/pagination';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import { MODULE, COMMON } from '@/constants/store.constant';
import { getAWSReportlURL } from '@/services/api/exchange-monitors.service';
import StateTag from '@/components/ui/StateTag';
import { formatDate, textFormatter } from '@/utils/global.util';
import CrawledDocuments from '@/components/collection-exchange-monitor/crawled-documents/CrawledDocuments';

export default {
  name: 'HistoryTable',
  components: {
    StoreProvider,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsPagination,
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    MdsSection,
    StateTag,
    CrawledDocuments,
  },
  filters: {
    formatDate,
    textFormatter,
  },
  data () {
    return {
      isModalVisible: false,
      paginationOptions: {
        page: 1,
        pageSize: 10,
      },
      pageSizeDefault: 10,
      collectionSelected: {},
    };
  },
  storeInfo: {
    module: MODULE.HISTORY_EXCHANGE_MONITOR,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
  headers: TABLE_HEADERS,
  computed: {
    historyEm () {
      return this.$store.state[MODULE.HISTORY_EXCHANGE_MONITOR].filterJobExecutionsHistory;
    },
    totalElements () {
      return this.$store.state[MODULE.HISTORY_EXCHANGE_MONITOR].totalElements;
    },
    paginatedData () {
      return this.historyEm;
    },
    showPagination () {
      return this.totalElements > this.pageSizeDefault;
    },
    isCollectionSelected () {
      return this.collectionSelected && Object.keys(this.collectionSelected).length > 0;
    },
  },
  watch: {
    isModalVisible: {
      handler (isModalVisible) {
        this.$emit('updateModalIsVisible', isModalVisible);
      },
    },
  },
  destroyed () {
    this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.RESET_STATE}`);
  },
  methods: {
    handlePageChange (paginationOptions) {
      this.paginationOptions = paginationOptions;
      this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_PARAM}`, { name: 'page', value: paginationOptions.page - 1 });
      this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_PARAM}`, { name: 'size', value: paginationOptions.pageSize === -1 ? this.totalElements : paginationOptions.pageSize });
      this.$store.commit(`${MODULE.HISTORY_EXCHANGE_MONITOR}/${COMMON.UPDATE_STATE}`, { name: 'shouldRefresh', value: true });
    },
    async openReport (awsBatchUuid) {
      const result = await getAWSReportlURL(awsBatchUuid);
      const { data: url } = result;
      url && window.open(url, '_blank');
    },
    getTagColor (text) {
      return STAGES[text] || STATUS[text] || '';
    },
    toggleModal (event, collectionSelected) {
      this.isModalVisible = !this.isModalVisible;
      this.collectionSelected = collectionSelected;
    },
    updateModalIsVisible (isModalVisible) {
      this.isModalVisible = isModalVisible;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@mds/typography';

::v-deep .mds-modal__wrapper__dap-crawler .mds-modal__content__dap-crawler {
  max-height: 100vh;
  overflow: scroll;
  margin: 30px;

  h2 {
    margin-left: $mds-space-2-and-a-half-x;
  }
}
.crawled-documents {
  &__title {
    @include mds-level-5-heading($bold: true);
  }
}
</style>
