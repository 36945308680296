<template>
  <mds-row
    align-horizontal="space-between"
    class="filter"
  >
    <mds-col :cols="6">
      <mds-search-field
        v-model="filterText"
        :placeholder="$t(`crawledDocuments.filter`)"
        class="filter__search"
        :label="$t(`crawledDocuments.searchLabel`)"
      />
    </mds-col>
    <mds-col
      :cols="6"
      class="filter__status"
    >
      <mds-button-group
        :content="buttonGroupContent"
        @mds-button-group-item-active="setActiveItem"
      />
    </mds-col>
  </mds-row>
</template>

<script>
import MdsSearchField from '@mds/search-field';
import MdsButtonGroup from '@mds/button-group';
import { MODULE, COMMON } from '@/constants/store.constant';

export default {
  name: 'FilterSection',
  components: {
    MdsSearchField,
    MdsButtonGroup,
  },
  data () {
    return {
      buttonGroupContent: [
        { id: '0', text: 'All', value: null, active: true },
        { id: '1', text: 'Merge', value: true, active: false },
        { id: '2', text: 'Unmerge', value: false, active: false }
      ],
    };
  },
  computed: {
    filterText: {
      get: function () {
        return this.$store.getters[`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.GET_STATE_BY_NAME}`]('filterText') ?? '';
      },
      set: function (newValue) {
        this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_PARAMS}`, { page: 0, size: 10, downloadedLink: newValue, s3Path: newValue, attributes: newValue });
        this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_STATE}`, { name: 'filterText', value: newValue });
        this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_STATE}`, { name: 'shouldRefresh', value: true });
      },
    },
    filterStatus: {
      get: function () {
        return this.$store.getters[`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.GET_STATE_BY_NAME}`]('filterStatus') ?? '';
      },
      set: function (newValue) {
        this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_PARAMS}`, { page: 0, size: 10, merged: newValue });
        this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_STATE}`, { name: 'filterStatus', value: newValue });
        this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_STATE}`, { name: 'shouldRefresh', value: true });
      },
    },
  },
  methods: {
    setActiveItem ({ currentTarget: { id } }) {
      this.buttonGroupContent.forEach((item) => {
        item.active = (item.id === id);
        if (item.active) {
          this.filterStatus = item.value;
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';
.filter {
  &__search {
    width: 400px;
  }
  &__status {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
