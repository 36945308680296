export const TABLE_HEADERS = [
  {
    fieldName: 'downloadedLink',
    text: 'Downloaded Link',
    style: {
      width: '20%',
    },
  },
  {
    fieldName: 's3Path',
    text: 'S3 Path',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'merged',
    text: 'Merged',
    style: {
      width: '3%',
    },
  },
  {
    fieldName: 'attributes',
    text: 'Attributes',
    style: {
      width: '5%',
    },
  },
  {
    fieldName: 'format',
    text: 'Format',
    style: {
      width: '5%',
    },
  },
  {
    fieldName: 'errorMessage',
    text: 'Error',
    style: {
      width: '15%',
    },
  }
];

export const PROFILE_HEADERS = [
  {
    fieldName: 'name',
    text: 'Name',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'id',
    text: 'Profile Uuid',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'url',
    text: 'URL',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'createdAt',
    text: 'Process Start Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'lastExecutedDate',
    text: 'Process End Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'crawlingTimeframeStart',
    text: 'Crawling Start Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'duration',
    text: 'Duration (Min)',
    style: {
      width: '10%',
    },
    sortable: true,
  },
  {
    fieldName: 'totalFiles',
    text: 'Total Files',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'frequency',
  },
  {
    fieldName: 'executions',
    text: 'Executions',
    style: {
      width: '7%',
    },
    sortable: true,
    sortableName: 'lastExecutedDate',
  },
  {
    fieldName: 'status',
    text: 'Status',
    sortable: true,
    style: {
      width: '12%',
    },
  },
  {
    fieldName: 'isActive',
    text: 'Is Active',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'nextExecutionDate',
  }
];
