<template>
  <mds-tag
    :class="`tag--${color.toLowerCase()}`"
    :text="status"
  />
</template>

<script>
import { MdsTag } from '@mds/tag';

export default {
  name: 'StatusTag',
  components: {
    MdsTag,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  &--success {
    background-color: $mds-feedback-color-success;
    color: $mds-background-color-white;
  }
  &--neutral {
    background-color: $mds-feedback-color-info;
  }
  &--info {
    background-color: $mds-interactive-color-primary-default;
    color: $mds-background-color-white;
  }
  &--warning {
    background-color: $mds-feedback-color-warning;
  }
  &--error {
    background-color: $mds-feedback-color-error;
    color: $mds-background-color-white;
  }
  &--orange {
    background-color: $mds-color-orange-54;
    color: $mds-background-color-white;
  }
}
</style>
