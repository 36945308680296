<template>
  <mds-layout-grid>
    <filter-section />
    <table-section />
  </mds-layout-grid>
</template>

<script>
import FilterSection from '@/components/history/filters/HistoryFilterSection';
import TableSection from '@/components/history/HistoryEM';

export default {
  name: 'HistoryPageEM',
  components: {
    FilterSection,
    TableSection,
  },
};
</script>
