<template>
  <div class="crawled-documents__table">
    <mds-row>
      <mds-col class="crawled-documents__title">
        <h3> {{ this.$t(`crawledDocuments.title`) }}</h3>
      </mds-col>
    </mds-row>
    <mds-row>
      <mds-col>
        <store-provider
          v-bind="$options.storeInfo"
        >
          <template>
            <mds-table row-hover>
              <mds-thead>
                <mds-th
                  v-for="header in $options.headers"
                  :key="header.fieldName"
                  :style="header.style"
                >
                  {{ header.text }}
                </mds-th>
              </mds-thead>
              <mds-tbody>
                <template
                  v-for="(row, index) in paginatedData"
                >
                  <mds-tr :key="index">
                    <mds-td
                      v-for="(header, i) in $options.headers"
                      :key="i"
                      :right-aligned="header.align === 'right'"
                      :style="header.style"
                    >
                      <template v-if="header.fieldName === 'downloadedLink'">
                        <a
                          :href="row[header.fieldName]"
                          target="_blank"
                        > {{ row[header.fieldName] }}</a>
                      </template>
                      <template v-else-if="header.fieldName === 's3Path'">
                        {{ row.s3Path ? showS3Path(row[header.fieldName]) : 'N/A' }}
                      </template>
                      <template v-else-if="header.fieldName === 'errorMessage'">
                        <template v-if="row[header.fieldName]">
                          {{ row[header.fieldName] }}
                          <mds-button
                            :id="`prompt-trigger-error${index}`"
                            variation="icon-only"
                            icon="open-new"
                            type="button"
                            text="Copy"
                            @click="copyErrorToClipboard(index, row[header.fieldName])"
                          />
                          <mds-tooltip
                            v-model="copiedError[index]"
                            :triggered-by="`prompt-trigger-error${index}`"
                            :position="['right-center']"
                            class="crawled-documents__tooltip"
                          >
                            Error Msg Copied!
                          </mds-tooltip>
                        </template>
                      </template>
                      <template v-else-if="header.fieldName === 'attributes'">
                        <mds-button
                          :id="`prompt-trigger${index}`"
                          variation="icon-only"
                          icon="open-new"
                          type="button"
                          text="Copy"
                          @click="copyDataToClipboard(index, row[header.fieldName])"
                        />
                        <mds-tooltip
                          v-model="copied[index]"
                          :triggered-by="`prompt-trigger${index}`"
                          :position="['right-center']"
                          class="crawled-documents__tooltip"
                        >
                          Attributes Copied!
                        </mds-tooltip>
                      </template>
                      <template v-else>
                        {{ row[header.fieldName] }}
                      </template>
                    </mds-td>
                  </mds-tr>
                </template>
              </mds-tbody>
            </mds-table>
          </template>
        </store-provider>
        <mds-pagination
          v-if="showPagination"
          :total-items="totalElements"
          :page="paginationOptions.page"
          :page-size="paginationOptions.pageSize"
          show-items-info
          show-items-select
          @mds-pagination-page-changed="handlePageChange"
        />
      </mds-col>
    </mds-row>
  </div>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider';
import { MODULE, COMMON } from '@/constants/store.constant';
import { TABLE_HEADERS } from '@/constants/crawled-documents.constant';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { MdsButton } from '@mds/button';
import MdsTooltip from '@mds/tooltip';
import MdsPagination from '@mds/pagination';

export default {
  name: 'CrawledDocumentsTable',
  components: {
    StoreProvider,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsTooltip,
    MdsPagination,
  },
  props: {
    showSuccess: {
      type: Boolean,
      default: () => false,
    },
  },
  data () {
    return {
      isModalVisible: true,
      paginationOptions: {
        page: 1,
        pageSize: 10,
      },
      pageSizeDefault: 10,
      copied: {},
      copiedError: {},
    };
  },
  computed: {
    crawledDocuments () {
      return this.$store.state[MODULE.CRAWLED_DOCUMENTS_EM].filterCrawledDocuments;
    },
    totalElements () {
      return this.$store.state[MODULE.CRAWLED_DOCUMENTS_EM].totalElements;
    },
    paginatedData () {
      return this.crawledDocuments;
    },
    showPagination () {
      return this.totalElements > this.pageSizeDefault;
    },
  },
  watch: {
    showSuccess: {
      handler (newShowSuccess) {
        this.paginationOptions = {
          page: 1,
          pageSize: 10,
        };
        this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_PARAMS}`, { showSuccess: newShowSuccess, page: 0, size: 10 });
        this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_STATE}`, { name: 'shouldRefresh', value: true });
      },
    },
  },
  mounted () {
    this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_PARAM}`, { name: 'showSuccess', value: this.showSuccess });
  },
  destroyed () {
    this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.RESET_STATE}`);
  },
  storeInfo: {
    module: MODULE.CRAWLED_DOCUMENTS_EM,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
  headers: TABLE_HEADERS,
  methods: {
    handlePageChange (paginationOptions) {
      this.paginationOptions = paginationOptions;
      this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_PARAM}`, { name: 'page', value: paginationOptions.page - 1 });
      this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_PARAM}`, { name: 'size', value: paginationOptions.pageSize === -1 ? this.totalElements : paginationOptions.pageSize });
      this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_STATE}`, { name: 'shouldRefresh', value: true });
    },
    async copyDataToClipboard (id, text) {
      await navigator.clipboard.writeText(text);
      this.$set(this.copied, id, true);
      setTimeout(() => {
        this.$set(this.copied, id, false);
      }, 2500);
    },
    async copyErrorToClipboard (id, text) {
      await navigator.clipboard.writeText(text);
      this.$set(this.copiedError, id, true);
      setTimeout(() => {
        this.$set(this.copiedError, id, false);
      }, 2500);
    },
    showS3Path (s3Path) {
      return `${process.env.VUE_APP_S3_FILE_BUCKET || 'dap-crawler-dev-em-downloaded-files'}/${s3Path}`;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@mds/typography';

.crawled-documents {
  &__title {
    @include mds-body-text-m();
  }
  &__table {
    max-height: 50%;
  }
  &__tooltip {
    margin-left: $mds-space-half-x;
  }
}
</style>
