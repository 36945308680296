<template>
  <div class="crawled-documents__body">
    <div>
      <mds-row>
        <mds-col>
          <mds-table row-hover>
            <mds-thead>
              <mds-th
                v-for="header in filteredHeaders"
                :key="header.fieldName"
                :style="header.style"
              >
                {{ header.text }}
              </mds-th>
            </mds-thead>
            <mds-tbody>
              <template>
                <mds-tr>
                  <mds-td
                    v-for="(header, i) in filteredHeaders"
                    :key="i"
                    :right-aligned="header.align === 'right'"
                    :style="header.style"
                  >
                    <template v-if="header.fieldName === 'isActive'">
                      <state-tag
                        :status="collectionSelected[header.fieldName] ? 'ACTIVE' : 'INACTIVE'"
                        :color="getTagColor(collectionSelected[header.fieldName])"
                      />
                    </template>
                    <template v-else-if="header.fieldName === 'status'">
                      <state-tag
                        :status="collectionSelected[header.fieldName]"
                        :color="getTagColorStage(collectionSelected[header.fieldName])"
                      />
                    </template>
                    <template v-else-if="header.fieldName === 'url'">
                      <a
                        :href="collectionSelected[header.fieldName]"
                        target="_blank"
                      > {{ collectionSelected[header.fieldName] }}</a>
                    </template>
                    <template v-else-if="header.fieldName === 'lastExecutedDate' || header.fieldName === 'createdAt' || header.fieldName === 'crawlingTimeframeStart'">
                      {{ collectionSelected[header.fieldName] | formatDate }}
                    </template>
                    <template v-else>
                      {{ collectionSelected[header.fieldName] }}
                    </template>
                  </mds-td>
                </mds-tr>
              </template>
            </mds-tbody>
          </mds-table>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col>
          <filter-section />
        </mds-col>
      </mds-row>
    </div>
    <mds-tabs
      :content="tabsContent"
      @mds-tabs-item-active="setActiveItem"
    />
    <crawled-documents-table
      v-if="showTable"
      :show-success="tabOption === 'completed' ? true : false"
    />
  </div>
</template>

<script>
import { MODULE, COMMON } from '@/constants/store.constant';
import { PROFILE_HEADERS } from '@/constants/crawled-documents.constant';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { MdsTabs } from '@mds/tabs';
import { STAGES, STATUS } from '@/constants/status-tags-colors.constant';
import StateTag from '@/components/ui/StateTag';
import { formatDate } from '@/utils/global.util';
import FilterSection from '@/components/collection-exchange-monitor/filters/CrawledDocumentsFilterSection';
import CrawledDocumentsTable from './CrawledDocumentsTable.vue';

export default {
  name: 'CrawledDocuments',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsTabs,
    StateTag,
    FilterSection,
    CrawledDocumentsTable,
  },
  filters: {
    formatDate,
  },
  props: {
    collectionSelected: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      isModalVisible: true,
      tabsContent: [
        {
          text: 'Completed',
          id: 'completed',
          active: false,
        },
        {
          text: 'Errors',
          id: 'errors',
          active: true,
        }
      ],
      tabOption: '',
      totalComplete: 0,
      totalErrors: 0,
      showTable: false,
    };
  },
  computed: {
    totalElements () {
      return this.$store.state[MODULE.CRAWLED_DOCUMENTS_EM].totalElements;
    },
    defaultShowSuccess () {
      return this.totalErrors > 0 ? 'errors' : 'completed';
    },
    filteredHeaders () {
      return this.$options.subheaders.filter((header) => {
        const value = this.collectionSelected[header.fieldName];
        return value && true && value !== '';
      });
    },
  },
  watch: {
    isModalVisible: {
      handler (isModalVisible) {
        this.$emit('updateModalIsVisible', isModalVisible);
      },
    },
    collectionSelected: {
      immediate: true,
      deep: true,
      async handler (newVal, oldVal) {
        if (newVal && newVal.jobId !== oldVal?.jobId) {
          this.fetchJobData(newVal.jobId);
        }
      },
    },
  },
  async mounted () {
    this.fetchJobData(this.collectionSelected.jobId);
  },
  destroyed () {
    this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.RESET_STATE}`);
  },
  subheaders: PROFILE_HEADERS,
  methods: {
    getTagColor (text) {
      const textFormatted = text ? 'ACTIVE' : 'INACTIVE';
      return STATUS[textFormatted] || '';
    },
    getTagColorStage (text) {
      return STAGES[text] || STATUS[text] || '';
    },
    setActiveItem (event, value = null) {
      this.tabOption = value || event.currentTarget.id;
      this.tabsContent.forEach((item) => {
        if (item.id === this.tabOption) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    async getCompleted () {
      await this.$store.dispatch(`${MODULE.CRAWLED_DOCUMENTS_EM}/getCompleted`);
      this.totalComplete = this.totalElements;
      this.tabsContent[0].text = `Completed (${this.totalComplete})`;
    },
    async getErrors () {
      await this.$store.dispatch(`${MODULE.CRAWLED_DOCUMENTS_EM}/getErrors`);
      this.totalErrors = this.totalElements;
      this.tabsContent[1].text = `Errors (${this.totalErrors})`;
    },
    async fetchJobData (jobId) {
      this.$store.commit(`${MODULE.CRAWLED_DOCUMENTS_EM}/${COMMON.UPDATE_PARAM}`, { name: 'jobId', value: jobId });
      await this.getErrors();
      await this.getCompleted();
      this.setActiveItem(null, this.defaultShowSuccess);
      this.showTable = true;
    },
  },
};

</script>

<style lang="scss" scoped>
.crawled-documents {
  &__body {
    padding: 20px;
  }
  &__icon {
    margin-left: $mds-space-1-x;
  }
}
</style>
